input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="search"], input[type="number"] {
  padding: 10px 20px;
  @include font-size(1.6);
  border-radius: $border-radius;
  box-sizing: border-box;
  border: 1px solid $color-main;
  display: block;
  width: 100%;
  @include bp(tablet) {
    padding: 5px;
    @include font-size(1.2);
    border-radius: 0;
  }
}
input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="search"]:focus, input[type="number"]:focus{
  border: 1px solid $color-main;
  background: lighten($color-main,85%);
}
label{
  display: block;
}
input[type="checkbox"], input[type="radio"]{
  vertical-align: middle;
  display: inline-block;
  float: left;
  margin: 3px 6px 0 0;
  & + label {
    display: inline-block;
    cursor: pointer;
    max-width: calc(100% - 20px);
  }
}
textarea {
  padding: 10px 20px;
  box-sizing: border-box;
  min-height: 100px;
  border: 1px solid $color-main;
  border-radius: 20px;
  @include font-size(1.6);
  width: 100%;

  &:focus{
    border: 1px solid $color-main;
    background: lighten($color-main,85%);
  }
}
.select-in-form {
  background: transparent;
  background: none\0;
  padding: 10px 20px;
  @include font-size(1.6);
  border-radius: $border-radius;
  box-sizing: border-box;
  border: 1px solid $color-main;
  display: block;
  width: 100%;
  @include bp(tablet) {
    padding: 5px;
    @include font-size(1.2);
    border-radius: 0;
  }
}

/*Sytle SELECT*/
.style-select {
  background: white;
  background: none\0;
  width: 100%;
  border: 1px solid darken($color-main,10%);
  border: none\0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  select {
    background: transparent;
    background: white\0;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%!important;
    padding: 10px;
    border: none;
    border: 1px solid $color-main \9;
    &:hover{
      cursor: pointer;
    }
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .style-select {
    background: none;
    border: none;
    select {
      background: white;
    }
  }
}
/*end Sytle SELECT*/

fieldset{
  border: none;
  padding: 0;
  &.error{
    border: 1px dotted $color-red;
    padding: 5px;
    border-radius: 5px;
  }
  p.error{
    text-align: left;
    color: $color-red;
    @include font-size(1.2);
    font-weight: $bold;
    margin: 5px 0 0 0;
  }
}
.checkbox-group{
  text-align: left;
  margin: 5px 0;
  input[type="checkbox"]{
    display: inline-block;
    width: 20px;
    float: none;
    margin: -5px 0 0 0;
    & + label{
      display: inline-block;
      width: calc(100% - 30px);
    }
  }
}
input{
  ::-webkit-input-placeholder {
    @include font-size(1.6);
  }
  :-moz-placeholder { /* Firefox 18- */
    @include font-size(1.6);
  }
  ::-moz-placeholder {  /* Firefox 19+ */
    @include font-size(1.6);
  }
  :-ms-input-placeholder {
    @include font-size(1.6);
  }
}