/********************************************************************************* MODALS */

/*  index

    ////// COMMONS
    ////// HEADER MODALS > My interests
*/

/*
    ////// COMMONS
*/
.main-modal{
    //position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map-get($zindex, modals);
}
.main-model-wrapper{
    @supports ( display: flex ) {
        @include flex-container;
        flex-direction: column;

        @include bp(mobile){
            display: block;
            height: 100vh;
            overflow: scroll;
        }
    }
}
.main-modal-header{
    text-align: right;
    padding: 15px 20px;
    width: 100%;
}
.main-modal-close{
    @include font-size(1.1);
    background: url(../img/icon-cross.svg) no-repeat right center;
    display: block;
    padding: 0 25px 0 0;
    font-weight: $bold;
    text-transform: uppercase;
}
.main-modal-content{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 300px 0 0 0;
    width: 100%;

    @supports ( display: flex ) {
        padding: 0;
    }
    @media screen and (max-width: $wrapper){
        padding-left: 20px;
        padding-right: 20px;
    }
}


/*
    ////// HEADER MODALS > My interests
*/
.my-interests{
    display: none;
    background: rgba($color-secondary,0.95);
}
.my-interests-content{
    h1{
        @include font-size(3.2);
        border-bottom: 1px solid $color-main;
        padding: 0 0 10px 0;
        margin: 0;
        font-weight: $medium;
    }

    ul{
        margin: 30px 0;
        columns: 2;

        @include bp(mobile){
            columns: 1;
        }
    }

    li{
        margin: 0;

        a{
            @include color-link($color-main);
            display: block;
            padding: 5px 0;
            border-bottom: 2px solid transparent;

            &:hover{
                border-bottom: 2px solid $color-main;
            }
        }
    }
}
.my-interests-aside-content{
    background: white;
    padding: 40px;
    min-height: 300px;

    @supports ( display: flex ) {
        @include flex-fixed-element(30vh);
        min-height: 1px;
    }

    ul{
        max-width: $wrapper;
        margin: 0 auto;

        @supports ( display: flex ) {
            @include flex-container;
        }
    }
    li{
        &.download-file{

            a{
                background: url(../img/icon-download.svg) no-repeat right center;
                padding: 10px 40px 10px 10px;
                display: block;
                @include color-link($color-main);
            }
        }
    }
}