/********************************************************************************* BUTTONS */

/*  index

    ////// MAIN STYLES
    ////// BUTTONS
*/

/*
    ////// MAIN STYLES
*/

.button, input[type="submit"]{
    display: inline-block;
    text-align: center;
    @include font-size(1.6);
    font-weight: $bold;
    @extend %main-transition;
    width: auto;
    padding: 10px 20px;
    border-radius: $border-radius;
    text-decoration: none;
    border: none;

    &.full-width {
        display: block;
        width: 100%;
    }
    &.disabled{
        opacity: 0.4;
        pointer-events: none;
    }
}

/*
    ////// BUTTONS
*/
.button_primary{
    @extend .button;
    background: $color-secondary;
    @include color-link(white);

    &:hover, &:active{
        background: darken($color-secondary,15%);
    }
}
.button_secondary{
    @extend .button;
    background: $color-main;
    @include color-link(white);

    &:hover, &:active{
        background: darken($color-main,25%);
    }
}
.button_secondary.button_outline {
    @extend .button;
    background: white;
    color: $color-main;
    text-decoration: none;
    border: solid 1px $color-main;
    &:hover, &:active{
        background: $color-main;
        color: white;
    }
}
.button_outline{
    @extend .button;
    background: transparent;
    border: 1px solid $color-main;
    @include color-link($color-main);

    &:hover, &:active{
        background: lighten($color-main,80%);
    }
}
.button_search{
    @extend .button;
    background: url("../img/icon-search-small.svg") no-repeat 15px center $color-secondary;
    @include color-link(white);
    padding-left: 50px;

    &:hover, &:active{
        background: url("../img/icon-search-small.svg") no-repeat 15px center darken($color-secondary,15%);
    }
}

.button_order_placed{
    @extend .button;
    background: no-repeat 15px center $color-green;
    @include color-link(white);

    &:hover, &:active{
        background: no-repeat 15px center darken($color-green,15%);
    }
}

.button_block,
.button-block {
    display: block !important;
    width: 100%;
}

/*
TOGGLE FAVORITE
 */
svg{
    cursor:pointer; overflow:visible; width:60px;
    #heart{transform-origin:center; animation:animateHeartOut .3s linear forwards;}
    #main-circ{transform-origin:29.5px 29.5px;}
}

.favorite{
    display:none !important;
}

.favorite:checked + label svg{
    #heart{transform:scale(.2); fill:#E2264D; animation:animateHeart .3s linear forwards .25s;}
    #main-circ{transition:all 2s; animation:animateCircle .3s linear forwards; opacity:1;}
    #grp1{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(0, -30px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(10px, -50px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp2{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(30px, -15px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(60px, -15px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp3{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(30px, 0px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(60px, 10px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp4{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(30px, 15px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(40px, 50px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp5{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(-10px, 20px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(-60px, 30px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp6{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(-30px, 0px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(-60px, -5px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp7{
        opacity:1; transition:.1s all .3s;
        #oval1{
            transform:scale(0) translate(-30px, -15px);
            transform-origin:0 0 0;
            transition:.5s transform .3s;}
        #oval2{
            transform:scale(0) translate(-55px, -30px);
            transform-origin:0 0 0;
            transition:1.5s transform .3s;}
    }
    #grp2{opacity:1; transition:.1s opacity .3s;}
    #grp3{opacity:1; transition:.1s opacity .3s;}
    #grp4{opacity:1; transition:.1s opacity .3s;}
    #grp5{opacity:1; transition:.1s opacity .3s;}
    #grp6{opacity:1; transition:.1s opacity .3s;}
    #grp7{opacity:1; transition:.1s opacity .3s;}
}

@keyframes animateCircle{
    40%{transform:scale(10); opacity:1; fill:#DD4688;}
    55%{transform:scale(11); opacity:1; fill:#D46ABF;}
    65%{transform:scale(12); opacity:1; fill:#CC8EF5;}
    75%{transform:scale(13); opacity:1; fill:transparent; stroke:#CC8EF5; stroke-width:.5;}
    85%{transform:scale(17); opacity:1; fill:transparent; stroke:#CC8EF5; stroke-width:.2;}
    95%{transform:scale(18); opacity:1; fill:transparent; stroke:#CC8EF5; stroke-width:.1;}
    100%{transform:scale(19); opacity:1; fill:transparent; stroke:#CC8EF5; stroke-width:0;}
}

@keyframes animateHeart{
    0%{transform:scale(.2);}
    40%{transform:scale(1.2);}
    100%{transform:scale(1);}
}

@keyframes animateHeartOut{
    0%{transform:scale(1.4);}
    100%{transform:scale(1);}
}