/********************************************************************************* COLORS */

/*  index

    ////// ESSENTIALS
    ////// CUSTOM
    ////// BUTTONS
    ////// TABLES
*/

/*
    ////// ESSENTIALS
*/
$color-main: 					#051A34;
$color-secondary: 				#FC3632;
$color-green:                   #0DAD00;
$color-red: 					#FC3632;
$color-dark-gray:               #565656;
$color-medium-gray:             #9E9E9E;
$color-light-gray:              #E2E2E2;
$color-super-light-gray:        #F6F6F6;

/*
    ////// CUSTOM
*/
$color-beige:                   #F9F7F6;
$color-yellow:                  #F2BD02;
$color-turquoise:               #0CD8E7;


/*
    ////// BUTTONS
*/
$color-main-button:             #FC3632;
$color-main-button-text:        white;
$color-main-button-text-hover:  white;

/*
    ////// TABLES
*/
$color-table-header: 			#FBFBFB;