@import '../../../Frontend/sass/partials/base/mixins';
@import '../../../Frontend/sass/partials/base/colors';


.cart-stripe-pricing {
  text-align: left;
  font-size: 1.2rem;

  ul {
    padding-left: 20px;
    line-height: normal;
  }

  ul > li {
    font-size: 1.2rem;
    margin: 0 0 5px 0;
    line-height: normal;

    padding-left: 1.5em;
    list-style: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyIj48cGF0aCBkPSJNMjAuNTU3IDQuNDc0TDguMzkyIDE2LjYzOWwtNC45NDktNC45NDhMMiAxMy4xODZsNi4zOTIgNi4zNEwyMiA1LjkxOGwtMS40NDMtMS40NDR6IiBmaWxsPSIjNDNhMDQ3IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 1em;
  }

  ul ul li {
    list-style: circle;
    background-image: none;
    padding-left: 0;
    margin-left: 1.5em;
  }

  .card-title {
    text-align: center;
    font-size: 2.6rem;
  }
  .green {
    color: green;
    font-weight: bold;
  }

  .cart-stripe-pricing-content {
    margin-bottom: 100px;
    padding-right: 1em;
  }

  .footer-plan {
    position: absolute;
    bottom: 55px;
    text-align: center;
    color: red;
    width: 100%;
  }

  .btn-plan {
    position: absolute;
    bottom: 0;
  }
}

.front-subscription-plans {
  width: 60%;
  margin: auto;

  @include bp(mobile){
    width: 100%;
  }

  .card {
    @include bp(mobile){
      display: block;
      width: 100%;
    }
  }

  h6 {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 1.25rem;
    text-align: center;
    background: $color-beige;
  }

  .cart-stripe-pricing-content {
    margin-bottom: 60px;
  }

  .cart-stripe-pricing {
    .footer-plan {
      bottom: 10px;
    }
  }
}

.stripe-advantages {
  text-align: left;
  font-size: 1.2rem;

  li {
    font-size: 1.2rem;
    margin: 0 0 5px 0;
  }
}