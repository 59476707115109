// Breakpoints
$xs: 0;
$sm: 48em; // 768px
$md: 64em; // 1024px
$lg: 75em; // 1200px
$xl: 100em; // 1600px

@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}
// special media queries, for any screen width values you need
@mixin any($screen) {
  @media (min-width: $screen) {
    @content;
  }
}


// Usage:
// @include sm {
//   css rule
// }
// @include any("50em") {
//   css rule
// }