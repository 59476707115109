.account-popin {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.95);
  z-index: 1032; /* above cookie message */
  align-items: center;
  justify-content: center;
  &.shown {
    display: flex;
  }
}
.account-popin--container {
  display: block;
  background: white;
  color: $color-main;
  border-radius: 10px;
  width: 40em;
  max-width: 90%;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
}
.account-popin--header {
  display: flex;
  background: $color-main;
  color: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  h4 {
    margin: 0 0 0 1em;
    font-weight: bold;
    letter-spacing: 1px;
  }
  a {
    display: block;
    font-size: 2rem;
    margin-left: 1em;
    color: white;
    padding: .5em;
  }
}
.account-popin--content {
  padding: 0 1em;
  h5.mb0 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
  .block {
    text-align: center;
    padding: 2em 1em;
    border-bottom: solid 1px $color-main;
    &:last-child {
      border-bottom: none;
    }
    a {
      display: inline-block;
      padding: 10px 20px;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 2.1rem;
      font-weight: 700;
      background-repeat: no-repeat;
      background-position: 15px center;
      text-decoration: none;
      .label {
        display: inline-block;
      }
      &:hover {
        .label {
          text-decoration: underline;
        }
      }
    }
  }
}