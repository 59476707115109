/********************************************************************************* LEGALS */

/*  index

    ////// COMMONS

*/


/*
    ////// COMMONS
*/
.legal-text{
    max-width: $wrapper;
    margin: 30px auto;

    h1{
        font-weight: $bold;
    }
    h2{
        margin: 30px 0 0 0;
    }
    h3{
        margin: 20px 0 0 0;
    }

    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}