.cart {
  .cart-title {
    @include bp(mobile){
      font-size: 2rem;
    }
  }
  .order-summary {
    table {
      border: none;
      @include bp(mobile) {
        display: block;
        thead {
          display: none;
        }
      }
    }
    tbody,
    tr {
      @include bp(mobile) {
        display: block;
      }
    }
    table td {
      border: none;
      vertical-align: middle;
      padding: 30px 15px 30px 15px;
      @include bp(mobile) {
        display: flex;
        padding: 15px;
        justify-content: space-between;
        align-items: center;
      }
    }
    .border-bottom {
      border-bottom: 1px solid black;
    }
    .border-bottom-th {
      border-bottom: 3px solid #F9F7F6;
    }
    .label-for-small-devices-only {
      display: none;
      @include bp(mobile) {
        display: block;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  #mondial-relay-widget {
    table {
      margin: 0;

      td {
        border: none;
      }
    }
  }

  .less-button {
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    margin-right:0;
    border: 1px solid black;
    border-radius: 0;
    border-top-left-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    background-color: #F9F7F6;
    &:hover {
      text-decoration:none;
    }
  }

  .quantity-as-button {
    background-color: white;
    border: 1px solid black;
    border-right: none;
    border-left: none;
    margin-right:0;
    margin-left:0;
    padding: 5px 10px 5px 10px;
  }

  .more-button {
    font-weight: bold;
    margin-left:0;
    padding: 5px 10px 5px 10px;
    border: 1px solid black;
    border-radius: 0;
    border-top-right-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
    background-color: #F9F7F6;
    &:hover {
      text-decoration:none;
    }
  }

  #shipping-cost-free-from-div p{
    font-size: 0.8em;
    font-weight: normal;
  }

  .cart-table {
    .product-img {
      width: 25%;
      @include bp(mobile) {
        width: 85%;
      }
    }
  }
}