/********************************************************************************* HOME */
/*  index
    ////// HEADER
    ////// FEATURED OFFERS
*/
/*
    ////// HEADER
*/
.homepage{
    .main-content{
        padding: 0 0 30px 0;
    }
}
.homepage-header{
    position: relative;
    .homepage-image{
        display: none;
        @include md {
            display: block;
            height: auto;
            min-height: 50vw;
            overflow: hidden;
            background: url(../../../../Frontend/img/homepage.jpg) no-repeat 50% 50%/auto 100%;
        }
    }
    .search-home{
        width: 100%;
        max-width: 1680px;
        @include md {
            position: absolute;
            top: 6rem;
            left: 50%;
            transform: translate(-50%,0);
        }
        @media (min-width: 1450px) {
            width: 95%;
            top: 200px;
            transform: translateX(-50%);
        }
        /*@media screen and (min-width: 1700px) {
            width: 80%;
        }*/
    }
    .baseline {
        font-family: "Nunito Sans", sans-serif;
        color: white;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        color: white;
        top: 2rem;
        font-size: 1.25rem;
        text-shadow: 2px 2px 2px rgba(0,0,0,.8);
        padding: 0 1rem;
        line-height: normal;
        font-weight: 600;
        @media (min-width: 768px) {
            font-size: 2.5rem;
            padding: 0 1rem;
            top: 1.5rem;
            letter-spacing: .25vw;
        }
        @media (min-width: 1024px) {
            font-size: 3vw;
        }
        @media (min-width: 1201px) {
            top: 4.5rem;
            letter-spacing: .5vw;
        }
        @media (min-width: 1600px) {
            font-size: 4rem;
        }
    }
    .baseline2 {
        display: none;
        @media (min-width: 1201px) {
            display: block;
            font-family: "Nunito Sans", sans-serif;
            text-align: center;
            line-height: normal;
            font-weight: bold;
            top: 13rem;
            color: white;
            text-shadow: 2px 2px 2px rgba(0,0,0,.8);
            letter-spacing: .125vw;
            margin-top: 5rem;
            font-size: 2.5rem;
            padding: 0 2vw;
        }
        @media (min-width: 1300px) {
            top: 35%;
        }
        @media (min-width: 1440px) {
            top: 45%;
        }
        @media (min-width: 1600px) {
            top: 50%;
        }
        @media (min-width: 1700px) {
            top: 55%;
        }
        @media (min-width: 2000px) {
            top: 60%;
        }
    }
    .baseline2 span {
        max-width: 1600px;
        display: block;
        margin: 0 auto;
    }
}
@media (min-width: 1024px) {
    .homepage-header .homepage-image {
        min-height: 600px;
    }
}
.homepage-header .search-home {
    @media (min-width: 1201px) {
        top: 25%;
    }
}
.search-box {
    @media (min-width: 568px) {
        margin-bottom: 1rem;
    }
}
/*
    ////// FEATURED OFFERS
*/
.featured-offers{
    margin: 50px 0;
}