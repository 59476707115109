/********************************************************************************* STORES */

/*  index

    ////// COMMONS
    ////// STORE LIST
    ////// STORE DETAIL
    ////// MAP VIEW

*/


/*
    ////// COMMONS
*/
.store-first-glance{
    position: relative;

    .store-main-category{
        position: absolute;
        top: -20px;
        right: -10px;
        z-index: 1000;
        width: 40px;
    }
    .store-main-category-list{
        position: absolute;
        top: -5px;
        right: -20px;
        z-index: 1000;
        width: 35px;
    }
    .store-main-category-list-left{
        position: absolute;
        top: -5px;
        left: -15px;
        z-index: 1000;
        width: 35px;
    }
    .store-click-and-collect-list{
        position: absolute;
        top: 32px;
        right: -20px;
        z-index: 1000;
        width: 35px;
    }
    .store-click-and-collect-list-left{
        position: absolute;
        top: 32px;
        left: -15px;
        z-index: 1000;
        width: 35px;
    }
    .store-image{
        //overflow: hidden;
        height: 120px;
        text-align: center;

        a{
            display: inline-block;
            height: 100%;
            width: auto;
            margin: 0 auto;
        }

        img.store-picture {
            display: block;
            width: auto;
            max-height: 120px;
            margin: 0 auto;
            @extend %main-transition;
        }
    }
}
.img-and-icons-container {
    display: inline-block;
    position: relative;
    max-height: 100%;
    object-fit: contain;
    .picture-container {
        display: inline-block;
        overflow: hidden;
    }
    .icons {
        display: inline-block;
        position: absolute;
        z-index: 1;
        width: 35px;
        left: -10px;
        top: -10px;
        line-height: 24px;
    }
}
.store-contact{
    margin: 20px 0;

    li{
        margin: 0;

        a{
            font-weight: $bold;
        }
    }
}
.store-main-information{
    h1{
        @include font-size(2.6);
        font-weight: $bold;
        margin: 0;

        a{
            @include color-link($color-main);
        }
    }
    .active-offers{
        @include font-size(1.6);
        color: $color-green;
    }

    .store-name {
        @include font-size(1.8);
        margin: 0 20px 0 0;
        display: inline-block;
        vertical-align: bottom;
        font-weight: bold;

        a{
            @include color-link($color-main);
        }
    }
}
.store-title{
    text-align: center;
    display: flex;
    justify-content: space-between;
}
.store-categories{
    margin: 12px 0;

    .store-category{
        @include font-size(1.6);
        font-weight: $bold;

        &:after{
            content: ",";
        }

        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}
.store-description{
    margin: 30px 0;
    text-align: justify;

    p{
        font-weight: $light;
    }
    li{
        margin: 0;
    }
    ol, ul{
        margin: 20px 0;
    }
    ul li{
        list-style-type: disc;
        list-style-position: inside;
    }
}
.spoken-languages{
    margin: 20px 0;

    p{
        margin: 0;
    }
}
.store-address{
    background: url("../img/icon-place.svg") no-repeat 20px center $color-beige;
    padding: 20px 20px 20px 40px;
    p{
        margin: 0;
    }
}
.store-address-favorite{
    background: url("../img/icon-place.svg") no-repeat 20px center;
    padding: 20px 20px 20px 40px;
    p{
        margin: 0;
    }
}
.store-schedule{
    margin: 20px 0;

    .store-opening-hours{
        margin: 0 0 10px 0;
    }

    li, p{
        margin: 0;
        @include font-size(1.4);
    }
    .day-name{
        font-weight: $bold;
    }
    .store-current-status{
        background: url("../img/icon-clock.svg") no-repeat left center;
        padding: 5px 0 5px 25px;
        margin: 0;
    }
    .closed{
        color: $color-red;
    }
    .opened{
        color: $color-green;
    }
}


/*
    ////// STORE LIST
*/
.store-result-list{
    .no-results{
        text-align: center;
        padding: 60px 10px 10px 10px;
        margin: 80px 0;
        background: url("../img/no-stores.svg") no-repeat center top;
        color: $color-secondary;
    }

}
.store-list{
    @media screen and (min-width: $wrapper) {
        /*display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 30px;*/
        columns: 2;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        .store-list-item{
            min-height: 220px;
        }
    }

    & > li {
        padding-top: 1.5rem;
    }

    .store-list-item{
        break-inside: avoid;
        display: grid;
        grid-template-columns: 1fr 5fr 2fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        border-bottom: 1px solid $color-main;
        margin: 0 0 30px 0;
        @extend %main-transition;
        padding: 5px;

        @media screen and (min-width: $wrapper){
            margin: 0;
        }

        @include bp(mobile){
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 10px;
            padding-bottom: 10px;
        }

        &:hover{
            background: $color-beige;

            .store-first-glance{
                .store-image{
                    //overflow: hidden;

                    .store-picture{
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
    .store-main-category{
        img{
            max-width: 40px;
        }
    }
    .store-main-information{
        h1{
            @include font-size(1.8);
            margin: 0 20px 0 0;
            display: inline-block;
            vertical-align: bottom;

            a{
                @include color-link($color-main);
            }
        }
        .active-offers{
            display: inline-block;
            vertical-align: bottom;
            margin: 0;
        }
        @include bp(mobile){
            grid-column: span 3;
        }
    }
    .store-categories{
        margin: 0;
    }
    .store-description{
        margin: 5px 0;

        p{
            @include font-size(1.4);
            margin: 0;
        }
    }
    .spoken-languages{
        p{
            @include font-size(1.4);
            margin: 0;
        }
    }
    .store-placement{
        text-align: right;

        @include bp(mobile){
            text-align: left;
        }
    }
    .store-address{
        background: none;
        padding: 0;

        p{
            @include font-size(1.4);
            position: relative;

            &:before{
                content: url("../img/icon-place.svg");
                position: relative;
                top: 3px;
                left: -5px;
                width: 14px;
                height: 20px;
            }
        }
    }
    .store-schedule{
        margin: 0;
    }
    .store-current-status{
        background: none;
        position: relative;
        padding: 0;

        &:before{
            content: url("../img/icon-clock.svg");
            position: relative;
            top: 6px;
            left: -5px;
            width: 20px;
            height: 20px;
        }

    }
    .store-contact{
        margin: 0;

        li{
            @include font-size(1.4);
        }
    }
}
.store-list-one-result {
    columns: 1;
}
/*
    ////// STORE DETAIL
*/
.store-detail-page{
    .product-list{
        .sold-in{
            display: none;
        }
        .product-list-item .product-price{
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .offer-header{
        .offer-store, .store-address{
            display: none;
        }
    }
    .offer-list{
        .offer-header{
            .store-categories{
                display: none;
            }
        }
        .offer-discount-text{
            @include flex-fixed-element(100%);
        }
    }
}
.store-detail{
    margin: 30px 0;

    .store-image{
        height: auto;
        max-height: 400px;
        background: none;
        overflow: hidden;
    }
}
.store-offers, .store-products{
    padding: 30px 0 50px 0;
}

.store-blog-post {
    color: $color-dark-gray;
    text-align: justify;

    .show-more {
        text-align: right;
    }
}

/*
    ////// MAP VIEW
*/
.map-store-card{
    .map-store-card-image{
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 0 0;
        width: 30%;

        img{
            max-width: 100%;
        }
    }
    .map-store-card-summary{
        display: inline-block;
        vertical-align: top;
        width: 63%;
    }
    h1{
        @include font-size(1.8);
        margin: 0;
    }
    p{
        @include font-size(1.4);
        margin: 0 0 5px 0;
    }
    .map-store-card-categories{
        font-weight: $light;
    }
    .map-store-card-address{
        font-weight: $normal;
    }
    .map-store-card-offerslabel{
        color: $color-green;
        background: url("../img/icon-search-offer.svg") no-repeat left center;
        background-size: 30px;
        padding: 5px 0 5px 30px;
    }
}