.cookies-alert {
  position: fixed;
  bottom: -10rem;
  left: 0;
  background: $color-beige;
  color: $color-dark-gray;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 0 1rem rgba($color-dark-gray,.25);
  z-index: 1031;
  transition: bottom .3s ease-in-out;
}
body {
  transition: margin-bottom .3s ease;
}
body.cookies-alert-visible .cookies-alert {
  display: block;
  bottom: 0;
}
body.cookies-alert-visible {
  .main-footer {
    padding-bottom: 12rem;
  }
}
/* graphic styles */
.cookies-alert p {
  line-height: 1.4em;
  margin-bottom: 0;
}
