/********************************************************************************* MENUS */

/*  index

    ////// GLOBALS
    ////// MAIN MENU
*/

/*
    ////// GLOBALS
*/
nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li { display: inline-block; }
}


/*
    ////// MAIN MENU
*/
.main-menu{

    #pull{
        display: none;
        padding: 10px 10px 10px 40px;
        margin: 25px 0 0 0;
        text-align: left;
        background: url(../img/essentials/icon-menu.svg) no-repeat right 10px center;
        @include color-link($color-main);

    }

    ul {
        @include flex-container;
        height: auto;
        justify-content: center;
        padding: 10px;
        margin: 0 auto;

        li {
            text-align: center;
            margin: 0 30px;
            @extend %font-family-secondary;
            text-transform: uppercase;
            @include font-size(1.4);

            a {
                display: block;
                height: 100%;

                &:hover {
                    color: $color-secondary;
                }
            }
            @media screen and (max-width: 1500px) {
                margin: 0 10px;
            }
        }
    }
}