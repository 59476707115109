/*GRID****************************/
html, body {
	height: 100%;
}

.center-wrapper {
	max-width: $wrapper;
	margin: 0 auto;
    
    &:after {content: "."; display: block; height: 0; clear: both; visibility: hidden;}

    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}
.full-width-wrapper{
    padding: 0 20px;

    @include bp(mobile){
        padding: 0 10px;
    }
}

.row {
	@include flex-container;
    height: auto;
}
.one-third-column {
    @include flex-fixed-element(32%);

    & + .two-third-column {
        @include flex-flexible-element;
        margin: 0 0 0 40px;

        @include bp(tablet){
            @include flex-fixed-element(100%);
        }
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.two-third-column {
    @include flex-fixed-element(65%);

    & + .one-third-column {
        @include flex-flexible-element;
        margin: 0 0 0 40px;

        @include bp(tablet){
            @include flex-fixed-element(100%);
        }
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.one-fourth-column {
    @include flex-fixed-element(22%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.three-fourth-column {
    @include flex-fixed-element(75%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.half-column {
    @include flex-fixed-element(48%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.col-8-12{
    @include flex-fixed-element(66%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.col-4-12{
    @include flex-fixed-element(33%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.col-5-12{
    @include flex-fixed-element(42%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.col-7-12{
    @include flex-fixed-element(58%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
    }
}
.full-width{
	@include flex-fixed-element(100%);
}

.float-right {
	float: right;
}
.float-left{
	float: left;
}
.content-with-sidebar-wrapper{
    @include flex-container;
}
.sidebar{
    @include flex-fixed-element(35%);
    margin: 0 30px 0 0;

    @include bp(tablet){
        @include flex-fixed-element(100%);
        order: 2;
    }
}
/*.main-content{
    @include flex-flexible-element;

    @include bp(tablet){
        @include flex-fixed-element(100%);
        order: 1;
    }
}*/

/*GRID CSS*/
//.grid{
//    display: grid;
//    grid-template-columns: 4fr 1fr 1fr;
//    grid-column-gap: 20px;
//    grid-row-gap: 40px;
//
//    h2{
//        grid-column: 2 / span 2;
//        grid-row: 1;
//        background: yellow;
//    }
//    p{
//        grid-row: span 2;
//        background: pink;
//    }
//
//}


/*Custom project sizes*/


.content-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @include bp(tablet){
        grid-template-columns: 1fr;
    }

    &.content_50_50{
        grid-template-columns: repeat(2, 1fr);

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_35_65{
        grid-template-columns: 3.5fr 6.5fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_65_35{
        grid-template-columns: 6.5fr 3.5fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_20_80{
        grid-template-columns: 2fr 8fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_80_20{
        grid-template-columns: 8fr 2fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_33_33_33{
        grid-template-columns: repeat(3, 1fr);

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
}