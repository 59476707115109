/********************************************************************************* VARIABLES */

/*  index

    ////// VARIABLES
    ////// PLACEHOLDERS
*/


/*
    ////// VARIABLES
*/

$text-shadow: 	0 1px 1px rgba(0,0,0,0.6);
$wrapper:       1400px;
$small-wrapper: 800px;

/*Media Queries*/
$break-mobile: 600px;
$break-tablet: 768px;
$break-desktop: 976px;
$break-superdesktop: 1200px;

$zindex: (
    header: 100,
    modals: 1000,
    below-item: 10,
    above-item: 20
);

$border-radius: 100px;

/*
    ////// PLACEHOLDERS
*/

%box-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.5);
  box-shadow: 0 1px 1px rgba(0,0,0,0.5);
}
%block-element{
	width: 100%;
	display: block;
	clear: both;
	float: none;
}
%vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
%main-transition{
  transition: all 0.2s ease-in-out;
}