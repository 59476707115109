/********************************************************************************* ACCESS */

/*  index

    ////// COMMONS
    ////// REGISTER
    ////// CONFIRMATION
    ////// LOGIN

*/


/*
    ////// COMMONS
*/
.access-box{
    margin: 0 auto;

    a{
        @include color-link($color-main);
        text-decoration: underline;
    }

    h1{
        text-align: center;
        color: $color-main;
        margin: 0 0 5px 0;

        @include font-size(3);

        @include bp(mobile){
            @include font-size(2.4);
        }
    }
    h2{
        text-align: center;
        margin: 0 0 50px 0;
        @include font-size(1.5);
    }
    .button_primary{
        margin: 20px 0;
    }

    @media screen and (min-width: 800px){
        width: 600px;
    }
    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}
.form-group{
    margin: 0 0 10px 0;
}

/*
    ////// REGISTER
*/
.register-intro{
    background: $color-beige;
    padding: 20px;
    margin: 0 0 30px 0;

    h2{
        @include font-size(2.2);
        font-weight: $bold;
    }
    ul{
        margin: 20px 0;
    }
    li{
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;
    }
}
.register-access-data, .register-commercial-data{
    margin: 0 0 30px 0;
}
.validate-phone{
    margin: 20px 0;
    padding: 5px 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;

    .hint{
        @include font-size(1.4);
        color: $color-medium-gray;
    }
    .validate-inputs{
        display: grid;
        grid-template-columns: 2fr 5fr;
        grid-column-gap: 10px;
    }
    .validate-action{
        position: relative;
        min-height: 40px;

        &:after {content: "."; display: block; height: 0; clear: both; visibility: hidden;}
    }
    .button_secondary{
        padding: 5px 20px;
        @include font-size(1.4);
        float: right;
    }
    .sending-success{
        color: $color-green;
        font-weight: $bold;
        @include font-size(1.4);
    }
    .sending-error{
        display: none;
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.4);
    }
    .add-code{
        display: none;

        p{
            font-weight: $bold;
            @include font-size(1.4);
        }
    }
}

/*
    ////// CONFIRMATION
*/
.register-confirmation-message{
    max-width: $small-wrapper;
    margin: 30px auto;
    text-align: center;
}


/*
    ////// LOGIN
*/
.login-box{
    .forgot-password{
        text-align: right;
        margin: 10px 0;
    }
}

.login-aside-actions, .recover-password-aside-actions{
    margin: 30px 0;
    border-top: 1px solid $color-main;
    padding: 20px 0 0 0;
    text-align: center;

    p{
        margin: 0;
        @include font-size(1.6);
    }
    a{
        @include color-link($color-main);
        text-decoration: none;
    }
}
.login-aside-actions{
    display: flex;
    justify-content: space-between;
}