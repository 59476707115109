/********************************************************************************* COMMONS */

/*  index

    ////// SNIPPETS > Breadcrumb
    ////// SNIPPETS > Search box
    ////// SNIPPETS > Mobile search field
    ////// SNIPPETS > Filters
    ////// SNIPPETS > Pager
*/


/*
    ////// SNIPPETS > Breadcrumb
*/
.breadcrumb{
    @include font-size(1.1);
    color: $color-medium-gray;
    text-transform: uppercase;
    margin-bottom: 10px;

    a{
        @include color-link($color-medium-gray);
    }
}

/*
    ////// SNIPPETS > Search box
*/
.search-box, .search-menu{
    margin: 0 0 30px 0;

    .search-type{

        display: none;
        @include md {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 10px;
        }

        li{
            margin: 0;
            a{
                padding: 10px 20px 10px 70px;
                border-radius: 10px 10px 0 0;
                display: block;
                width: 100%;
                height: 100%;
                max-width: none;
                margin: 0;
                box-sizing: border-box;
            }

            &.search-offers{

                a{
                    background: url("../img/icon-search-offer.svg") no-repeat 15px 10px $color-red;
                    color: white;

                    @include bp(tablet){
                        background-size: 30px;
                    }

                    @include bp(mobile){
                        background-size: 30px;
                        background-position: center center;
                    }

                    &:hover{
                        text-decoration: none;
                        background: url("../img/icon-search-offer.svg") no-repeat 15px 10px darken($color-red,15%);

                        @include bp(tablet){
                            background-size: 30px;
                        }

                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }
                    }
                }
                &.selected{
                    a{
                        background: url("../img/icon-search-offer.svg") no-repeat 15px 10px $color-beige;
                        color: $color-main;

                        @include bp(tablet){
                            background-size: 30px;
                        }

                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }

                        &:hover{
                            text-decoration: none;
                            background: url("../img/icon-search-offer.svg") no-repeat 15px 10px $color-beige;

                            @include bp(tablet){
                                background-size: 30px;
                            }

                            @include bp(mobile){
                                background-size: 30px;
                                background-position: center center;
                            }
                        }
                    }
                }
            }
            &.search-stores{

                a{
                    background: url("../img/icon-search-store.svg") no-repeat 15px 10px $color-red;
                    color: white;

                    @include bp(tablet){
                        background-size: 30px;
                    }

                    @include bp(mobile){
                        background-size: 30px;
                        background-position: center center;
                    }

                    &:hover{
                        text-decoration: none;
                        background: url("../img/icon-search-store.svg") no-repeat 15px 10px darken($color-red,15%);

                        @include bp(tablet){
                            background-size: 30px;
                        }

                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }
                    }
                }
                &.selected{
                    a{
                        background: url("../img/icon-search-store.svg") no-repeat 15px 10px $color-beige;
                        color: $color-main;

                        @include bp(tablet){
                            background-size: 30px;
                        }
                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }

                        &:hover{
                            text-decoration: none;
                            background: url("../img/icon-search-store.svg") no-repeat 15px 10px $color-beige;

                            @include bp(tablet){
                                background-size: 30px;
                            }
                            @include bp(mobile){
                                background-size: 30px;
                                background-position: center center;
                            }
                        }
                    }
                }
            }
            &.search-products{

                a{
                    background: url("../img/icon-search-product.svg") no-repeat 15px 10px $color-red;
                    color: white;

                    @include bp(tablet){
                        background-size: 30px;
                    }

                    @include bp(mobile){
                        background-size: 30px;
                        background-position: center center;
                    }

                    &:hover{
                        text-decoration: none;
                        background: url("../img/icon-search-product.svg") no-repeat 15px 10px darken($color-red,15%);

                        @include bp(tablet){
                            background-size: 30px;
                        }
                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }
                    }
                }
                &.selected{
                    a{
                        background: url("../img/icon-search-product.svg") no-repeat 15px 10px $color-beige;
                        color: $color-main;

                        @include bp(tablet){
                            background-size: 30px;
                        }
                        @include bp(mobile){
                            background-size: 30px;
                            background-position: center center;
                        }

                        &:hover{
                            text-decoration: none;
                            background: url("../img/icon-search-product.svg") no-repeat 15px 10px $color-beige;

                            @include bp(tablet){
                                background-size: 30px;
                            }
                            @include bp(mobile){
                                background-size: 30px;
                                background-position: center center;
                            }
                        }
                    }
                }
            }
        }
        @include bp(tablet){
            grid-column-gap: 10px;
        }
    }
    .type-title{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0;

        @include bp(tablet){
            @include font-size(1.4);
        }
        @include bp(mobile){
            display: none;
        }
    }
    .type-hint{
        @include font-size(1.4);
        margin: 0;

        @include bp(desktop){
            @include font-size(1.2);
        }
        @media screen and (max-width: 750px){
            display: none;
        }
    }
    /*.tab-content.current{
        display: inherit;
    }*/

    .search-form-fields{
        padding: 30px 28px 20px 28px;
        background: $color-beige;
        margin-top: -1px;
        position: relative;
        z-index: 10;
        box-sizing: border-box;
        width: 100%;

        @include bp(tablet){
            @include flex-container;
            padding: 10px 15px 5px;

            input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="search"], input[type="number"]{
                padding: 5px;
            }

            fieldset:not(.open-search) {
                display: none;
            }

            &.shown{
                fieldset:not(.open-search) {
                    display: block;
                }
            }
        }
        fieldset, .button_primary, .button_search, .button-order-placed{
            display: inline-block;
            vertical-align: top;
        }
        .button_search{
            padding-left: 40px;
            @include bp(tablet){
                display: none;
            }
        }
        .button_primary, .button_search, .button-order-placed{
            float: right;
            margin-top: 18px;

            @include bp(desktop){
                float: none;
            }

            @include bp(mobile){
                @include flex-fixed-element(100%);
                order: 7;
            }
        }
        fieldset{
            position: relative;
            margin: 0 15px 0 0;
            @media screen and (max-width: 1730px){
                margin: 0 7px 0 0;
            }
        }
        label{
            @include font-size(1.2);
            @include md {
                padding-left: 20px;
            }
        }
        .categories-search, .borough-search{

            .categories-input{
                background: url("../img/icon-list.svg") no-repeat right 20px center white;
            }
            .selection{
                background: url("../img/icon-list.svg") no-repeat right 20px center white;
                display: block;
                border: 1px solid $color-main;
                border-radius: 100px;
                height: 46px;
                @include bp(tablet) {
                    height: auto;
                    border-radius: 0;
                }

                .select2-selection.select2-selection--single{
                    background: none;
                    border: none;
                }
                .select2-selection__arrow{
                    display: none;
                }
                .select2-selection__rendered{
                    padding: 8px 20px;
                    width: calc(100% - 3rem);
                    height: 46px;
                    overflow: hidden;
                    @include bp(tablet) {
                        height: auto;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 5px;
                        font-size: 12px;
                    }

                    .category-thumbnail{
                        display: inline-block;
                        vertical-align: top;
                        width: 30px;
                        height: 25px;
                        text-align: center;
                    }
                    .category-name{
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% - 50px);
                        white-space: normal;
                    }
                }
            }
        }
        .borough-search {
            //min-width: 130px;
            min-width: 200px;

            .selection {
                .select2-selection__rendered {
                    @include flex-container;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: flex-start;
                    flex-wrap: nowrap;
                    width: 80%;
                    padding: 5px 0 0 20px;
                    position: relative;
                    //overflow: scroll;
                    height: 46px;
                    overflow: hidden;

                    .select2-selection__clear{
                        position: absolute;
                        left: 5px;
                        top: 5px;
                    }
                    .select2-selection__choice{
                        @include font-size(1.2);
                        display: inline-block;
                        vertical-align: top;
                        padding: 0;
                        margin: 0 3px 3px 0;
                    }
                }
            }
            .select2-container--default .select2-selection--multiple{
                background: none;
                border: none;
            }
        }
        .city-input{
            background: url("../img/icon-place.svg") no-repeat right 20px center white;
        }
        .postalcode-input{
            background: url("../img/icon-place.svg") no-repeat right 20px center white;
        }
        .categories-list, .cities-list{
            display: none;
            position: absolute;
            background: white;
            z-index: 1000;
            width: calc(100% - 40px);
            border: 1px solid darken($color-beige,10%);
            left: 50%;
            transform: translateX(-50%);


            li{
                margin: 0;
                @include font-size(1.4);
                border-bottom: 1px solid darken($color-beige,10%);
            }
            a{
                display: block;
                padding: 5px 10px;
                @extend %main-transition;
                @include color-link($color-main);

                &:hover{
                    background: darken($color-beige,3%);
                }
                &:active{
                    background: $color-red;
                    color: white;
                }
            }
        }
        .open-search{
            width: 23%;

            @media screen and (max-width: 1730px){
                width: 20%;
            }
            @include bp(desktop){
                width: 48%;
                margin: 0 10px 10px 0;
            }
            @include bp(tablet){
                width: 100%;
                margin: 0 0 10px 0;
            }
        }
        .categories-search{
            width: 20%;

            @include bp(desktop){
                width: 48%;
                margin: 0 10px 10px 0;
            }
            @include bp(tablet){
                @include flex-fixed-element(100%);
                order: 2;
                margin: 15px 0 10px 0;
            }
            select {
                margin-bottom: 0;
            }
        }
        .cities-search{
            width: 20%;

            @include bp(desktop){
                width: 48%;
                margin: 0 10px 10px 0;
            }
            @include bp(tablet){
                @include flex-fixed-element(100%);
                order: 3;
                margin: 0 0 10px 0;
            }
        }
        .postalcode-search{
            @include bp(desktop){
                width: 48%;
                margin: 0 10px 10px 0;
            }
            @include bp(tablet){
                @include flex-fixed-element(100%);
                order: 4;
                margin: 0 0 10px 0;
            }
        }
        .stores-with-offers, .products-with-offers{
            display: inline-block;
            width: 30%;
            max-width: 30%;
            @include md {
                width: auto;
                max-width: 100%;
                white-space: nowrap;
                margin-left: 1em;
                margin-top: 2em;
            }
            @include lg {
                margin-left: 0;
                margin-right: 1em;
                margin-top: 1em;
            }
            label{
                padding: 0;
                @include font-size(1.4);
                @include lg {
                    max-width: 100%;
                    margin-right: 2em;
                }
                @media (min-width: 1024px) and (max-width: 1024px) {
                    position: relative;
                    font-size: 1.2rem;
                    letter-spacing: -.25px;
                    top: -3px;
                }
            }
        }
        .stores-with-offers{
            @include bp(mobile){
                @include flex-fixed-element(100%);
                order: 5;
                margin: 0 0 10px 0;
            }
        }
        .products-with-offers{
            @include bp(mobile){
                @include flex-fixed-element(100%);
                order: 5;
                margin: 0 0 10px 0;
            }
        }
    }

    &.with-borough{

        .open-search{
            width: 20%;
        }
        .categories-search{
            width: 18%;
        }
        .cities-search{
            width: 18%;
        }

        @media screen and (min-width: 1200px) and (max-width: 1500px){
            input[type="submit"]{
                background: url("../img/icon-search-small.svg") no-repeat center center $color-secondary;
                @include color-link(white);
                width: 44px;
                padding: 10px;
                overflow: hidden;
                color: transparent;

                &:hover, &:active{
                    background: url("../img/icon-search-small.svg") no-repeat center center darken($color-secondary,15%);
                }
            }
        }
        @include bp(desktop){
            .search-form-fields{
                .cities-search{
                    width: 55%;
                }
                .postalcode-search{
                    width: 24%;
                }

            }
        }
        @include bp(tablet){
            .search-form-fields{
                .open-search, .categories-search, .cities-search, .borough-search{
                    width: 47%;
                }
            }
        }
        @include bp(mobile){
            .search-form-fields{
                .open-search, .categories-search, .cities-search, .postalcode-search, .borough-search{
                    @include flex-fixed-element(100%);
                }
                .borough-search{
                    order: 4;
                    margin: 0 0 10px 0;
                }
                .postalcode-search{
                    order: 5;
                }
            }
        }
    }
}
/*
Kind of hack:
On
- other pages than homepage,
- landscape tablet (1024px width, to be true) and lower widths,
we "enlarge" .search-box so it has the same width as in hompage (i.e. full screen width)
*/
body:not(.homepage) .search-box {
    @media (max-width: 1024px) {
        margin-left: -20px;
        margin-right: -20px;
    }
    @media (max-width: 700px) {
        margin-left: -10px;
        margin-right: -10px;
    }
}
.select2-container{
    .select2-dropdown.select2-dropdown--below{
        border: 1px solid $color-medium-gray!important;
        margin-top: -1px;

        .select2-search__field{
            padding: 4px 20px;
        }
    }
    .select2-results__option{
        margin: 0;
        border-bottom: 1px solid $color-light-gray;
        @include font-size(1.4);

        .category-thumbnail{
            display: inline-block;
            vertical-align: top;
            width: 30px;
            height: 25px;
            text-align: center;
        }
        .category-name{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 50px);
            white-space: normal;
        }
    }
}
.ui-widget.ui-widget-content{
    border: 1px solid $color-medium-gray!important;
    z-index: 10000;
    margin-top: -1px;

    li{
        border-bottom: 1px solid $color-light-gray;
        @include font-size(1.4);
        list-style-image: none;

        &:hover{
            border: none;
            border-bottom: 1px solid $color-light-gray;
        }
    }
    a{
        display: block;
        border: none;

        &:hover{
            background: $color-beige;
            color: $color-main;
            border: 1px solid transparent;
        }
    }
    .department{
        color: $color-medium-gray;
        @include font-size(1.4);
        font-style: italic;
        font-variant: all-small-caps;
    }
}
.search-menu{
    //max-width: $wrapper;
    margin: 0 auto;
    text-align: left;
}
.search-options-triggers {
    display: none;
    @include bp(tablet) {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
.trigger-search-form,
.trigger-search-form-sort {
    display: inline-block;
    @include font-size(1.4);
    @extend %main-transition;
    border-bottom: dotted 1px transparent;
    padding-bottom: .25em;
    margin-bottom: .5em;
    &:before{
        display: inline-flex;
        content: '+';
        width: 1.5em;
        height: 1.5em;
        align-items: center;
        justify-content: center;
        border: solid 1px $color-main;
        border-radius: 100%;
    }
    &.active {
        border-bottom-color: $color-main;
    }
    &.active:before{
        content: '-';
    }
}

/*
    ////// SNIPPETS > Mobile search field
*/
.open-search {
    input {
        @include bp(tablet){
            display: inline-block;
            width: calc(100% - 3rem);
            float: left;
        }
    }
    button[type="submit"] {
        display: none;
        span.label {
            display: none;
        }
        @include bp(tablet){
            display: inline-block;
            padding: 5px;
            background: $color-main;
            color: white;
            border: solid 1px $color-main;
            font-size: 1.2rem;
            line-height: 1.8rem;
            width: 3rem;
        }
    }
}

/*
    ////// SNIPPETS > Filters
*/
.filters{
    margin: 20px 0 10px;
    @include flex-container;
    height: auto;
    @include bp(tablet) {
        display: none;
    }
    &.shown {
        @include bp(tablet) {
            display: flex;
        }
    }

    .view-options{
        @include bp(mobile){
            @include flex-fixed-element(100%);
        }
    }
    .view-map{
        display: inline-block;
        background: url("../img/icon-map.svg") no-repeat 10px center;
        border: 1px solid darken($color-beige, 10%);
        padding: 10px 20px 10px 50px;
        @include font-size(1.4);
        font-weight: $bold;
        @extend %main-transition;

        &.selected, &:hover{
            text-decoration: none;
            background: url("../img/icon-map.svg") no-repeat 10px center $color-beige;
            border-color: $color-main;
        }

        @include bp(mobile){
            width: 48%;
            text-align: left;
        }
    }
    .view-list{
        display: inline-block;
        background: url("../img/icon-list-big.svg") no-repeat 10px center;
        border: 1px solid darken($color-beige, 10%);
        padding: 10px 20px 10px 50px;
        @include font-size(1.4);
        font-weight: $bold;
        @extend %main-transition;

        &.selected, &:hover{
            text-decoration: none;
            background: url("../img/icon-list-big.svg") no-repeat 10px center $color-beige;
            border-color: $color-main;
        }

        @include bp(mobile){
            width: 48%;
            text-align: left;
        }
    }
    .sort-options{
        .sort-options-title, .style-select{
            display: inline-block;
            vertical-align: middle;
            width: auto;
            @include font-size(1.4);
        }
        .sort-options-title{
            margin: 0 10px 0 0;
            font-weight: $bold;
        }

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 10px 0 0 0;
        }
    }
    .style-select{
        background: url("../img/arrow-select.svg") no-repeat right 10px center;
        border: 1px solid darken($color-beige, 10%);

        select{
            padding-right: 40px;
        }
    }
}
.see-all-results{

    .total-results{
        display: inline-block;
        vertical-align: middle;
        @include font-size(1.4);
    }
    .reset-form{
        @include font-size(1.4);
        display: inline-block;
        vertical-align: middle;
        background: url("../img/icon-refresh.svg") no-repeat 15px center $color-beige;
        padding: 7px 20px 7px 50px;
        border-radius: 100px;
        margin: 0 0 0 10px;
        @extend %main-transition;

        &:hover{
            background: url("../img/icon-refresh-hover.svg") no-repeat 15px center darken($color-beige,5%);
        }
    }
    @include bp(tablet){
        @include flex-fixed-element(100%);
        order: 3;
        margin: 20px 0 0 0;
        text-align: center;
    }
}

/*
    ////// SNIPPETS > Pager
*/
.pager{
    @include flex-container;
    margin: 50px auto;
    height: auto;

    li{
        margin: 0;
        @include font-size(1.4);
    }

    .previous{
        a{
            background: url("../img/arrow-left.svg") no-repeat center center;
            display: block;
            width: 20px;
            height: 20px;
        }

    }
    .current-page{
        text-align: center;
        font-weight: bold;
        text-decoration: underline;
    }
    .next{
        a{
            background: url("../img/arrow-right.svg") no-repeat center center;
            display: block;
            width: 20px;
            height: 20px;
        }
    }
}