/********************************************************************************* PRODUCTS */

/*  index

    ////// COMMONS
    ////// PRODUCT LIST
    ////// PRODUCT DETAIL
    ////// MAP VIEW

*/


/*
    ////// COMMONS
*/
.product-first-glance{
    position: relative;
    @include bp(tablet) {
        order: 2;
    }

    .product-main-category{
        position: absolute;
        top: -20px;
        right: -10px;
        z-index: 1000;
    }
    .product-images{
        .swipebox{
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            &:hover{
                &:after{
                    content: url("../img/icon-search-big.svg");
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 50px;
                    height: 50px;
                    animation: fadeIn;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-delay: 0s;
                    animation-fill-mode: forwards;
                }
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            @extend %main-transition;

        }
    }
}
.product-price{
    margin: 10px 0;

    .product-real-price{
        @include font-size(2);
        font-weight: $bold;
    }

    .product-offer-price{
        @include font-size(2);
        font-weight: $bold;

        & + .product-real-price{
            @include font-size(1.8);
            font-weight: $normal;
            color: $color-red;
            text-decoration: line-through;
        }
    }
}
.click-and-collect{
    @include font-size(1.6);
    color: $color-green;
}
.product-categories{
    margin: 12px 0;

    .product-category{
        @include font-size(1.6);
        font-weight: $bold;

        &:after{
            content: ",";
        }

        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}
.product-featured-labels{
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1000;

    .product-discount{
        background: $color-green;
        padding: 5px 5px 5px 10px;
        border-radius: 20px 0 0 20px;
        text-align: center;
        @include font-size(1.1);
        font-weight: $bold;
        color: white;
    }
    .product-new{
        background: $color-yellow;
        padding: 5px 5px 5px 10px;
        border-radius: 20px 0 0 20px;
        text-align: center;
        @include font-size(1.1);
        font-weight: $bold;
        color: $color-main;
    }
}


/*
    ////// PRODUCT LIST
*/
.product-result-list{
    .no-results{
        text-align: center;
        padding: 60px 10px 10px 10px;
        margin: 80px 0;
        background: url("../img/no-products.svg") no-repeat center top;
        color: $color-secondary;
    }
}
.product-list{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    .product-list-item{
        border: 1px dotted $color-main;
        padding: 30px 10px 10px 10px;
        position: relative;
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        @extend %main-transition;

        p{
            margin: 0;
        }
        .product-image {
            overflow: hidden;
            height: 200px;
            margin: 0 0 10px 0;
            //background: $color-beige;

            a{
                display: block;
                height: 100%;
                width: 100%;
                position: relative;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                @extend %main-transition;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }

            @include bp(tablet) {
                height: 160px;
            }
            @include bp(mobile) {
                height: 110px;
            }
            @include bp(minimobile) {
                height: auto;
            }
        }
        .product-price{
            border-bottom: 1px solid $color-main;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        &:hover{
            background: $color-beige;

            .product-image{
                img{
                    transform: translate(-50%,-50%) scale(1.2);
                }
            }
        }
    }
    .product-main-category{
        position: absolute;
        left: 10px;
        top: -20px;
        display: inline-flex;
        img{
            max-width: 40px;
            max-height: 40px;
            margin-right: 5px;
        }
    }
    .product-title{
        @include font-size(1.8);
        font-weight: $bold;
    }
    .sold-in{
        p{
            @include font-size(1.4);
            margin: 0;

            &.sold-in-title{
                color: $color-medium-gray;
                font-style: italic;
            }
            &.sold-in-store{
                font-weight: $bold;

                a{
                    @include color-link($color-main);

                    &:hover{
                        color: $color-secondary;
                    }
                }
            }
            &.sold-in-address{
                @include font-size(1.2);
            }
        }
    }

    @media screen and (min-width: $wrapper){
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
    /*@media screen and (min-width: 1800px){
        grid-template-columns: repeat(10, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }*/
    @include bp(tablet){
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
    }
    @include bp(mobile){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(minimobile){
        grid-template-columns: repeat(1, 1fr);
    }
    .related-products &, .store-products &{
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        @include bp(tablet){
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 20px;
        }
        @include bp(mobile){
            grid-template-columns: repeat(2, 1fr);
        }
        @include bp(minimobile){
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

/*
    ////// PRODUCT DETAIL
*/
.product-detail{
    margin: 50px 0;

    .product-first-glance {

        .product-main-category {
            right: auto;
            left: 10px;
            display: inline-flex;
            img{
                margin-right: 5px;
                width: 40px;
            }
        }
    }
    .product-main-information{
        @include bp(tablet) {
            order: 3;
        }
        h1{
            margin: 0;
            font-weight: $bold;
        }
        .store-description{
            margin: 20px 0;
        }
    }
    .product-images{
        #box-container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;
        }
        .box{
            //border: 1px solid darken($color-beige,10%);
            //background: $color-beige;

            &:nth-child(1){
                grid-column: span 3;
            }
        }
    }
    .product-variations{
        margin: 20px 0;

        li{
            margin: 0;
            list-style-type: none;
        }
        .variation-name{
            font-weight: $bold;
        }
        .variation-values{

        }
    }
    .product-offer-price{
        margin: 0 5px 0 0;
    }
}
.store-seller{
    @include bp(tablet) {
        order: 1;
    }
    .sold-in-hint{
        @include font-size(1.4);
        font-style: italic;
        color: darken($color-beige,30%);
    }
}
.store-card{
    background: url("../img/store-card-label.svg") no-repeat right top $color-beige;
    padding: 20px;

    .store-list-item{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-template-areas:
                'image information information'
                'image placement placement';
    }
    .store-first-glance{
        grid-area: image;

        .store-main-category{

            img{
                max-width: 40px;
            }
        }
    }
    .store-main-information{
        grid-area: information;

        h1{
            @include font-size(1.8);
            margin: 0;

            a{
                @include color-link($color-secondary);

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .active-offers{
        display: none;
    }
    .store-categories{
        @include font-size(1.4);
        margin: 0 0 10px 0;

        .store-category{
            @include font-size(1.4);
            font-weight: $normal;
        }
    }
    .store-description{
        display: none;

    }
    .click-and-collect{
        @include font-size(1.6);
        color: $color-green;
    }
    .store-address{
        background: url("../img/icon-place.svg") no-repeat left center;
        padding: 0 0 0 20px;

        p{
            @include font-size(1.4);
        }
    }
    .store-contact{
        margin: 0;

        li{
            @include font-size(1.4);
        }
    }
    .store-schedule{
        margin: 5px 0;
    }
    .store-placement{
        grid-area: placement;
    }
}

/*
    ////// MAP VIEW
*/
.map-store-card{
    .map-store-card-product-list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin: 15px 0;
    }
    .map-store-card-product-item{
        position: relative;
        border: 1px dotted $color-main;
        padding: 7px;
    }
    .map-store-card-product-discount{
        position: absolute;
        right: 0;
        top: 5px;
        background: $color-green;
        padding: 5px 10px;
        color: white;
        box-sizing: border-box;
        z-index: 1000;
    }
    .map-store-card-product-image{
        width: 100%;
        max-width: 100px;
        max-height: 100px;
        margin: 0;
        overflow: hidden;

        img{
            width: 100%;
            max-width: 100px;
            height: 100px;
            object-fit: cover;
            @extend %main-transition;

            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .map-store-card-product-summary{
        h2, p{
            margin: 0;
        }
        h2{
            @include font-size(1.4);
            font-weight: $bold;
        }
        .map-store-card-product-original-price{
            @include font-size(1.6);
            font-weight: $bold;
        }
        .map-store-card-product-offer-price{
            @include font-size(1.6);
            font-weight: $bold;

            & + .map-store-card-product-original-price{
                @include font-size(1.4);
                text-decoration: line-through;
                font-weight: $normal;
                color: $color-secondary;
            }
        }
    }
    .see-more-products{
        .button_primary{
            padding: 2px 12px;
            @include font-size(1.2);
        }
    }
}

.modal {
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 30%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}