/********************************************************************************* FOOTER */

/*  index

    ////// NEWSLETTER
    ////// MAIN FOOTER
*/


/*
    ////// NEWSLETTER
*/
.newsletter-subscription{
    background: $color-beige;
    padding: 50px 20px 20px 20px;
    text-align: center;
    position: relative;
    margin-top: 50px;

    @include bp(mobile){
        padding: 50px 10px 20px 10px;
    }

    &:before{
        content: url("../img/icon-envelope-big.svg");
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
    }

    .title{
        @include font-size(2.2);
        font-weight: $normal;
        margin: 0;

        @include bp(mobile){
            @include font-size(2);
            line-height: 2.4rem;
        }
    }
    .subtitle{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0;

        @include bp(mobile){
            @include font-size(1.6);
        }
    }
    form{
        width: 90%;
        margin: 20px auto;

        label{
           @include font-size(1.4);
        }

        @media screen and (min-width: 800px){
            width: 425px;
        }
    }
    .button_secondary{
        margin: 15px 0;
    }
}


/*
    ////// MAIN FOOTER
*/
.footer{
    @include flex-container;
    height: auto;
    background: $color-main;
    padding: 20px;
    color: white;

    p, li{
        margin: 0;

        @include bp(mobile){
            @include font-size(1.4);
        }
    }

    .footer-links{
        @include flex-fixed-element(33%);

        a{
            text-decoration: underline;
            @include color-link(white);
        }
        ul{
            margin: 20px 0 0 0;
        }

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
        }
    }
    .footer-contact{
        @include flex-fixed-element(33%);
        text-align: center;

        p{
            @include font-size(1.8);
            font-weight: $bold;

            @include bp(mobile){
                @include font-size(1.4);
            }
        }

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
            text-align: left;
        }
    }
    .credits{
        @include flex-fixed-element(33%);
        text-align: right;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
            text-align: left;
        }
    }
}
.social-icons{
    @include flex-container;
    justify-content: center;
    height: auto;
    margin: 5px 0;

    li{
        margin: 0 4px;

        a{
            display: block;
        }
    }
    img{
        max-width: 30px;
    }
}