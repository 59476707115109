.my-owl-container {
  width: 960px;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  font-family: sans-serif;
  padding: 0 1.5rem;
}
.owl-visuel-produit {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.owl-stage {
  display: flex;
  align-items: stretch;
}
.owl-carousel .owl-item {
  float: none;
  display: flex;
  align-items: stretch;
}
.my-owl-slide-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: black;
  text-decoration: none;
  margin: 1.5rem;
  padding: 1.5rem;
  border: solid 1px #ddd;
  font-size: 1.5rem;
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0 0 1.5rem rgba(0,0,0,.2);
    background: #F9F7F6;
  }
}
.my-owl-prev,
.my-owl-next {
  display: flex;
  border: none;
  background: white;
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.my-owl-prev {
  left: -3rem;
}
.my-owl-next {
  right: -3rem;
}
a.owl-nom-produit {
  font-weight: bold;
  display: block;
  margin: 1.5rem 0;
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.owl-img-container {
  display: flex;
  height: 160px;
  justify-content: center;
}
.owl-add-to-cart {
  display: block;
  background: white;
  color: $color-main;
  padding: 1rem 2rem;
  border: solid 1px $color-main;
  margin: 1rem auto 0;
  cursor: pointer;
  transition: background-color .3s;
  border-radius: $border-radius;
  font-weight: bold;
  &:hover {
    background: $color-main;
    color: white;
  }
}
.owl-prix-produit {
  display: block;
  text-align: center;
  b {
    font-size: 2rem;
  }
  s {
    color: red;
  }
}
.owl-macarons {
  display: flex;
  position: absolute;
  top: 0;
  left: 2rem;
  white-space: nowrap;
  img {
    max-width: 40px;
    height: 40px;
    margin-right: .5rem;
    border-radius: 50%;
  }
}
.owl-cartouche-pourcent {
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 2.5rem;
  background: #0DAD00;
  padding: 5px 10px 5px 20px;
  border-radius: 20px 0 0 20px;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 700;
  color: white;
}
.owl-taille-selector {
  display: block;
  margin: .5rem 0;
}
label.owl-inline-label {
  display: inline !important;
  margin: 0;
  padding: 0;
}