/*INDEX THEME
	
	- BASE
    - LAYOUT
    - COMPONENTS
	- PAGES
    - PLUGINS 
	- MEDIAQUERIES
    - OLDIES

************************************************************************************/

/* 
=======================
PROJECT STYLES
======================= 
*/

/*MEDIAQUERIES*/
@import 'mediaqueries/tweakpoints';
@import 'mediaqueries/mobile_first';

/*BASE*/
@import 'base/colors';
@import 'base/variables';
@import 'base/mixins';
@import 'base/typography';
@import 'base/animations';
@import 'base/print';

/*LAYOUT*/
@import 'layout/grid';

/*COMPONENTS*/
@import 'components/commons';
@import 'components/header';
@import 'components/footer';
@import 'components/tables';
@import 'components/forms';
@import 'components/navigation';
@import 'components/buttons';
@import 'components/modals';
@import 'components/snippets';
@import 'components/cookies';
@import 'components/account-popin';
@import 'components/iciaya-icons';

/*PAGES*/
@import 'pages/home';
@import 'pages/access';
@import 'pages/stores';
@import 'pages/products';
@import 'pages/offers';
@import 'pages/errors';
@import 'pages/legals';
@import 'pages/cart';
@import 'pages/owl-custom';

// Use on store_manager_register to diplay pricing
@import '../../../Backend/bootstrap/scss/bootstrap/card';
@import '../../../Backend/sass/pages/stripe_pricing';

/*PLUGINS*/
@import 'plugins/swipebox';
@import 'plugins/off-canvas-menu';

/*OLDIES*/
@import 'oldies/modernizr';
@import 'oldies/hacks';

/******* IF edition out of SCSS System is needed, please use "auxiliar.css" file in /css directory **************/

/*
=======================
PROJECT STYLES
=======================
*/