.svg-defs {
  display: none;
  position: absolute;
  left: -9999rem;
  width: 0;
  height: 0;
  overflow: hidden;
}
i.ii {
  display: inline-block;
  font-style: normal;
  width: 1em;
  height: 1em;
  &.mr-1 {
    margin-right: 1em;
  }
  &.mr-0_5 {
    margin-right: .5em;
  }
  svg {
    max-width: 1em;
    max-height: 1em;
    fill: currentColor;
  }
}