// http://v4-alpha.getbootstrap.com/components/card/ no flex no round corners no mixins

//
// Base styles
//

$card-spacer-x:            1.25rem;
$card-spacer-y:            .75rem;
$card-border-width:        .0625rem;
$card-border-color:        #e5e5e5;

$card-cap-bg: #f5f5f5;

.card {
  position: relative;
  margin-bottom: $card-spacer-y;
  border: $card-border-width solid $card-border-color;
}

.card-block {
  padding: $card-spacer-x;
}

.card-title {
  margin-top: 0;
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

// .card-actions {
//   padding: $card-spacer-y $card-spacer-x;

//   .card-link + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}


//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;
}


//
// Background variations
//

//.card-primary {
//  background-color: $brand-primary;
//  border-color: $brand-primary;
//}
//.card-success {
//  background-color: $brand-success;
//  border-color: $brand-success;
//}
//.card-info {
//  background-color: $brand-info;
//  border-color: $brand-info;
//}
//.card-warning {
//  background-color: $brand-warning;
//  border-color: $brand-warning;
//}
//.card-danger {
//  background-color: $brand-danger;
//  border-color: $brand-danger;
//}


//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  .card-header,
  .card-footer {
    border-bottom: .075rem solid rgba(255,255,255,.2);
  }
  .card-header,
  .card-footer,
  .card-title,
  .card-blockquote {
    color: #fff;
  }
  .card-link,
  .card-text,
  .card-blockquote > footer {
    color: rgba(255,255,255,.65);
  }
  // .card-link {
  //   @include hover-focus {
  //     color: #fff;
  //   }
  // }
}


//
// Blockquote
//

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}


.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}




//
// Card set
//


.card-deck {
  display: table;
  table-layout: fixed;
  border-spacing: 1.25rem 0;

  .card {
    display: table-cell;
    width: 1%;
    vertical-align: top;
  }
}
.card-deck-wrapper {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}


//
// Card groups
//

.card-group {
  display: table;
  width: 100%;
  table-layout: fixed;


  .card {
    display: table-cell;
    vertical-align: top;
    + .card {
      margin-left: 0;
      border-left: 0;
    }
  }
}


//
// Card
//

.card-columns {
  column-count: 3;
  column-gap: 1.25rem;

  .card {
    display: inline-block;
    width: 100%; // Don't let them exceed the column width
  }
}