/********************************************************************************* COMMONS */

/*  index

    ////// GLOBALS
    ////// COMMON ELEMENTS
    ////// MAP VIEW
    ////// MARGIN HACKS
*/

/*
    ////// GLOBAL SYTLES
*/

.text-right { text-align: right; }
.text-center { text-align: center; }
.text-bold { font-weight: $bold; }
.no-border {border: none !important; }

body {
    @extend %font-family-main;
	@include font-size(1.6);
	color: $color-main;
    font-weight: $normal;

    &.frozen{
        overflow: hidden;
    }
}
ul{
	li{
		list-style-type: none;
	}
}
ol{
	li{
		list-style-type: decimal;
		list-style-position: inside;
	}
}
a{
	cursor: pointer;
    
    &:link{ color: $color-main;}
    &:hover{text-decoration: underline;}
    &:visited{color: $color-main;}
    &:active{color: $color-main;}
}
img{
    max-width: 100%;
}
.main-content{
    padding: 30px 0;
    &.pageContainsSearchBoxAndIsNotHomepage {
        @include bp(tablet) {
            padding-top: 0;
        }
    }
}
.bg-wrapper{
    background: url("../img/main-bg.svg");
    padding: 20px 0;

    .center-wrapper{
        background: white;
        padding: 0 20px;
        border-radius: 4px;
    }
}

/*
    ////// COMMON ELEMENTS
*/
.feedback{
    margin: 10px 0;
    text-align: left;

    &.success{
        background: url("../img/icon-tick.svg") no-repeat left 5px;
        padding: 5px 5px 5px 30px;
        color: $color-green;
        font-weight: $bold;
    }
    &.error{
        background: url("../img/icon-alert.svg") no-repeat left 5px;
        padding: 5px 5px 5px 30px;
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.4);
    }
    p{
        @include font-size(1.4);
    }
}
.new-search{
    text-align: center;
    margin: 0 0 20px 0;
    border-bottom: 10px solid $color-beige;

    .full-width-wrapper &{
        margin: 0;
    }
}
.side-actions{
    @include flex-container;
    height: auto;
    padding: 20px 0;

    .back-to-list{
        background: url("../img/arrow-left.svg") no-repeat left center;
        padding: 5px 0 5px 25px;
    }
    .social-share{
    }
}
.section-title{
    text-align: center;
    position: relative;
    margin-bottom: 50px;

    &:before{
        content: '';
        border-top: 1px dashed $color-secondary;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10px;
        z-index: 10;
    }

    h2{
        @include font-size(2.6);
        font-weight: $light;
        border: 1px dashed $color-secondary;
        border-bottom: none;
        border-radius: 30px 30px 0 0;
        margin: 0 0 10px 0;
        width: auto;
        display: inline-block;
        padding: 10px 100px 0 100px;
        background: white;
        position: relative;
        z-index: 20;

        @include bp(mobile){
            padding: 10px 20px;
            @include font-size(2.2);
            border-radius: 30px 30px 0 0;
        }
    }
}
.plain-text{
    max-width: 1000px;
    margin: 30px auto;

    h1{
        font-weight: $bold;
    }
    h2{
        margin: 30px 0 0 0;
    }
    h3{
        margin: 20px 0 0 0;
    }

    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}
.plain-text-intro{
    margin: 0 0 50px 0;

    h1{
        margin: 0;
    }
}
.block-image-text{
    margin: 0 0 30px 0;

    img{
        max-width: 100%;
        width: 100%;
    }

    @include bp(tablet){
        .block-image{
            order: 1;
        }
        .block-text{
            order: 2;
        }
    }
}


/*
    ////// MAP VIEW
*/
.map-view{
    display: none;

    .gm-style .gm-style-iw-c{
        padding: 20px!important;
    }
    .gm-ui-hover-effect{
        top: 0!important;
        right: 0!important;
    }
    .map-store-card-summary{
        &:hover{
            h1{
                text-decoration: underline;
            }
        }
    }
}

/*
    ////// MARGIN HACKS
*/

$decalages: 10;

@for $i from 1 through $decalages {
    .mt-minus-#{$i} {
        margin-top: -#{$i}px;
    }
}

.display-block-md-only {
    @include bp(tablet){
        display: block;
    }
}