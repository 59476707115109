/********************************************************************************* OFFERS */

/*  index

    ////// COMMONS
    ////// OFFERS LIST
    ////// OFFER DETAIL
    ////// MAP VIEW
    ////// OFFER PREVIEW

*/


/*
    ////// COMMONS
*/
.offer-header{
    @include flex-container;
    height: auto;
    margin: 0 0 10px 0;

    .offer-store{
        @include font-size(2);
        line-height: 2.4rem;
        font-weight: $bold;
        margin: 0;
    }
    .store-categories{
        margin: 0 0 5px 0;
        line-height: 1.6rem;

        .store-category{
            @include font-size(1.4);
            line-height: 1.6rem;
            font-weight: $normal;
        }
    }
    .store-address{
        background: url("../img/icon-place.svg") no-repeat left 3px;
        padding: 0 0 0 20px;
        min-height: 25px;

        p{
            @include font-size(1.2);
        }
    }
}
.offer-main-category{
    @include flex-fixed-element(50px);

    img{
        max-width: 40px;
    }
    @include bp(tablet){
        position: absolute;
        top: -20px;
        left: -10px;
    }
}
.offer-seller{
    @include flex-flexible-element;

    a{
        @include color-link($color-main);
    }
}
.offer-discount-text{
    @include flex-fixed-element(35%);
    text-align: center;

    p{
        @include font-size(1.2);
        line-height: 1.8rem;
        margin: 0;

        &.offer-discount-value{
            @include font-size(4);
            line-height: 4.5rem;
            font-weight: $bold;
            color: $color-green;

            @include bp(tablet){
                @include font-size(3);
                line-height: 3.5rem;
            }
        }
    }
    @include bp(tablet){
        @include flex-fixed-element(30%);
    }
    @include bp(mobile){
        @include flex-fixed-element(40%);
    }
}
.offer-footer{
    text-align: center;
    margin: 20px 0 10px 0;
}

/*
    ////// OFFERS LIST
*/
.offer-result-list{
    .no-results{
        text-align: center;
        padding: 60px 10px 10px 10px;
        margin: 80px 0;
        background: url("../img/no-offers.svg") no-repeat center top;
        color: $color-secondary;
    }
}
.offer-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media screen and (min-width: $wrapper){
        grid-template-columns: repeat(4, 1fr);
    }
    @include bp(desktop){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
    }
}
.offer-list-item{
    padding: 20px;
    border-radius: 4px;
    margin: 0;
    background: $color-beige;
    height: 100%;
    @extend %main-transition;

    &:hover{
        background: darken($color-beige,3%);
    }

    @include bp(tablet){
        position: relative;
    }

    &.offer-type-banner{
        border: 1px solid $color-turquoise;

        .offer-content-type-banner{
            //position: relative;
        }
        .offer-content-type-banner-image{
            width: 100%;
            overflow: hidden;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .offer-content-type-banner-text{
            /*position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);*/
            text-align: center;
            //text-shadow: 0 3px 5px rgba(255,255,255,0.8);
            //width: 95%;
            width: 96%;
            /*background: rgba(#fff,0.5);
            border-radius: 5px;
            padding: 10px;*/
            padding: 10px 0;

            p{
                @include font-size(1.8);
                line-height: 2rem;
                margin: 0;
            }
        }
        h2{
            @include font-size(2.4);
            line-height: 3rem;
            font-weight: $bold;
            margin: 0;
        }
        .button_primary{
            background: $color-turquoise;

            &:hover{
                background: darken($color-turquoise,10%);
            }
        }
    }
    &.offer-type-discount{
        border: 1px solid $color-green;

        .product-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;


            .product-title{
                @include font-size(1.4);
                line-height: 1.6rem;
            }
            .product-main-category, .sold-in{
                display: none;
            }
            .product-price{
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;

                .product-offer-price{
                    @include font-size(1.6);
                }
                .product-real-price{
                    @include font-size(1.4);
                }
            }
            .product-featured-labels{
                .product-new, .product-discount{
                    @include font-size(1.2);
                    padding: 2px 5px 2px 10px;
                }
            }
            .product-list-item .product-image{
                height: 100px;
            }

            @include bp(tablet){
                grid-template-columns: repeat(2, 1fr);
            }
            @include bp(mobile){
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .button_primary{
            background: $color-green;

            &:hover{
                background: darken($color-green,10%);
            }
        }
    }
}
.store-offers{
    .offer-list{
        grid-template-columns: repeat(3, 1fr);

        @include bp(desktop){
            grid-template-columns: repeat(2, 1fr);
        }
        @include bp(desktop){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .offer-list-item{
        &.offer-type-discount{
            .product-list{
                @include bp(tablet){
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }

}

/*
    ////// OFFER DETAIL
*/

/*
    ////// MAP VIEW
*/
.map-view{
    .offer-result-list &{
        .map-store-card-summary{
            width: 100%;
        }
    }
    .announcement-banner{
        background: $color-beige;
        padding: 10px;
        border: 1px solid $color-turquoise;
        border-radius: 4px;
        margin: 10px 0;

        .announcement-banner-image{
            display: inline-block;
            vertical-align: top;
            width: 30%;
        }
        .announcement-banner-text{
            display: inline-block;
            vertical-align: top;
            width: 63%;

            p{
                margin: 0;
                @include font-size(1.4);

                &.announcement-banner-text-title{
                    @include font-size(1.6);
                    font-weight: bold;
                }
            }
        }
    }
    .announcement-product{
        background: $color-beige;
        padding: 10px;
        border: 1px solid $color-green;
        border-radius: 4px;
        margin: 10px 0;

        .announcement-product-products{
            display: inline-block;
            vertical-align: top;
            width: 63%;
        }
        .offer-product{
            display: inline-block;
            vertical-align: top;
            width: 28%;
            margin: 0 5px 5px 0;

            a{
                display: block;
                width: 80px;
                height: 80px;
                max-width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .announcement-product-discount{
            display: inline-block;
            vertical-align: top;
            width: 30%;
            text-align: right;

            p{
                margin: 0;
                font-weight: $bold;

                &:nth-child(2){
                    @include font-size(1.2);
                    font-weight: $normal;
                }
            }
        }
        .announcement-product-discount-value{
            @include font-size(2.4);
            line-height: 2.6rem;
            font-weight: bold;
            color: $color-green;
            display: block;
        }
    }
}

/*
    ////// OFFER PREVIEW
*/
.annonce-preview-page{
    background: darken($color-beige,10%);
}
.annonce-preview{
    max-width: 435px;
    margin: 0 auto;
}