/********************************************************************************* ERRORS */

/*  index

    ////// MAIN ERROR PAGE
    ////// 404
    ////// 403

*/


/*
    ////// MAIN ERROR PAGE
*/
.error-page{
    text-align: center;
    padding: 50px 0;

    h1{
        color: $color-secondary;
        font-weight: $bold;
        @include font-size(3);
        margin: 0;
    }
}