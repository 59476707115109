/********************************************************************************* HEADER */

/*  index

    ////// HEADER
    ////// TOP ACTIONS
*/

/*
    ////// HEADER
*/

.header{
    @include flex-container;
    height: auto;
    align-items: center;
    border-bottom: 1px dotted $color-main;
    padding: 15px 20px 0 20px;
    position: relative;
    overflow: hidden;

    @include bp(tablet){
        padding: 15px 10px 4px 10px;
        justify-content: space-between;
    }

    &:before{
        content: '';
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fc3632+0,72fd76+100 */
        background: #fc3632; /* Old browsers */
        background: -moz-linear-gradient(left, #fc3632 0%, #72fd76 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #fc3632 0%,#72fd76 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #fc3632 0%,#72fd76 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc3632', endColorstr='#72fd76',GradientType=1 ); /* IE6-9 */
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 6px solid $color-main;
    }

    .logo{
        @include flex-fixed-element(225px);
        @include md {
            padding: 10px 0;
        }
        a{
            display: block;
            text-align: center;
            img{
                display: inline-block;
                max-width: 180px;
                margin: 0 auto;
                @include bp(tablet){
                    max-width: 100px;
                }
            }
        }

        .claim{
            margin: 0;
            @include font-size(1.3);
            float: left;
            width: 100%;
            @include bp(tablet){
                display: none;
            }

        }
        @include bp(tablet){
            @include flex-fixed-element(100px);
            text-align: center;
            margin-top: 5px;
        }
        @include bp(mobile){

        }
    }
    .top-actions{
        display: flex;
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        @media (max-width: 1023px) {
            display: none;
        }
    }

    &.with-menu{
        .logo{
            @include flex-fixed-element(20%);

            @media screen and (max-width: 1300px){
                @include flex-fixed-element(30%);
                order: 1;
            }
            @include bp(mobile){
                @include flex-fixed-element(100%);
                padding: 5px 0;

                img{
                    max-width: 150px;
                }
            }
        }
        .main-menu{
            @include flex-flexible-element;

            @media screen and (max-width: 1600px){
                @include flex-fixed-element(40%);
            }
            @media screen and (max-width: 1300px){
                @include flex-fixed-element(100%);
                order: 3;

                ul{
                    padding: 0;
                }
                li{
                    margin: 0 30px;
                }
            }
            @include bp(mobile){
                margin-bottom: 10px;
            }

            li{
                @include bp(mobile){
                    @include flex-fixed-element(100%);
                    border-bottom: 1px solid $color-light-gray;
                    margin: 0;

                    &:last-child{
                        border-bottom: none;
                    }

                    a{
                        padding: 5px 0;
                    }
                }
            }
        }
        .top-actions{
            @include flex-fixed-element(40%);

            @media screen and (max-width: 1300px){
                @include flex-fixed-element(70%);
                order: 2;
            }
        }
    }
}



/*
    ////// TOP ACTIONS
*/

.top-actions{
    a {
        display: inline-flex;
        padding: 1em 0;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1;
        @include color-link($color-main);
        margin-left: 1.5em;
        position: relative;
        align-items: center;
        &:first-child {
            margin-left: 0;
        }
        i {
            font-size: 1.125em;
            margin-right: .25em;
        }
        &:hover,
        &.active {
            cursor: pointer;
            text-decoration: none;
            i {
                text-decoration: none;
            }
            .label {
                text-decoration: underline;
            }
        }
    }
    .pastille {
        font-size: 1.25rem;
        top: .125em;
    }
}

button.burger-menu-trigger {
    display: inline-flex;
    background: none;
    border: none;
    margin: 7px 5px 0 5px;
    padding: 0;
    font-size: 3rem;
    flex-direction: column;
    align-items: center;
    color: $color-main;
    @include md {
        display: none;
    }
    .label {
        display: block;
        font-size: 1rem;
        line-height: 1;
    }
}
.top-actions {
    display: none;
    @include md {
        display: flex;
    }
}
.top-actions--pre--mobile {
    display: flex;
    margin-right: auto;
    margin-left: .5em;
    @include font-size(2);
    @include md {
        display: none;
    }
    .label {
        display: none;
    }
    a {
        display: block;
        width: 20px;
        text-align: center;
    }
}
.top-actions--mobile {
    display: flex;
    @include font-size(2);
    justify-content: flex-end !important;
    margin-left: auto;
    padding-right: .25em;
    .label {
        display: none;
    }
    @include md {
        display: none;
    }
    a {
        position: relative;
        display: inline-block;
        margin-left: 1em;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: .25em;
        }
    }
    .pastille {
        font-size: 1.5rem;
    }
}
.pastille {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    background: $color-secondary;
    color: white;
    border-radius: 50%;
    top: -.5em;
    right: -1em;
    line-height: 0;
}
.claim--mobile {
    display: none;
    flex-basis: 100%;
    text-align: center;
    margin: 0;
    @include font-size(1);
    @include bp(tablet) {
        display: block;
    }
}