////////// off-canvas menu

.off-canvas-menu--wrap a {
  color: $color-main;
}
.off-canvas-menu--wrap a:hover,
.off-canvas-menu--wrap a:focus {
  color: $color-main;
}

/* Close Button */
.off-canvas-menu--close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  overflow: hidden;
  //text-indent: 1em;
  font-size: 24px;
  border: none;
  background: transparent;
  padding: 0;
  color: $color-main;
  .label {
    display: none;
  }
}

/* Menu */
.off-canvas-menu--wrap {
  position: fixed;
  z-index: 1032;
  width: 100vw;
  height: 100%;
  background: white;
  font-size: 1.15em;
  top: 0;
  left: -100vw;
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.off-canvas-menu--menu {
  height: 100%;
}
.icon-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.icon-list a {
  display: flex;
  padding: 1em;
  align-items: center;
  border-bottom: solid 1px $color-main;
  &:hover {
    text-decoration: none;
    span {
      text-decoration: underline;
    }
  }
  &:first-child {
    padding: 0;
    justify-content: center;
    img {
      width: 150px;
      margin: .5em;
    }
  }
}
.icon-list a span {
  display: inline-block;
  margin-left: 1em;
  font-weight: 700;
}

.icon-list a.active span {
  text-decoration: underline;
}

/* Shown menu */
.show-menu .off-canvas-menu--wrap {
  transform: translate3d(100vw,0,0);
  transition: transform 0.8s;
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}
.show-menu .main-content::before {
  opacity: 1;
  transition: opacity 0.8s;
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);
  transform: translate3d(0,0,0);
}

body.show-menu {
  overflow: hidden; // prevents body scroll if menu is open.
}

// Icons
.off-canvas-menu--wrap {
  i[class^="icon-"] {
    display: inline-block;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-size: 32px;
    background-position: center center;
    content: '';
    align-self: center;
  }
  .icon-offer {
    background: url("../img/icon-search-offer.svg") no-repeat;
  }
  .icon-store {
    background: url("../img/icon-search-store.svg") no-repeat;
  }
  .icon-product {
    background: url("../img/icon-search-product.svg") no-repeat;
  }
}
.icon-list {
  .fas,
  .ii {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-main;
    background: white;
    border-radius: 100%;
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}